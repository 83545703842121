<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-50">
        Thống kê sinh viên ở các danh mục
      </b-card-title>
    </b-card-header>
    
    <b-card-body>
      <vue-apex-charts v-if="check"
                       ref="StudentByItemApexChart"
                       id="StudentByItemApexChart"
                       type="bar"
                       height="400"
                       :options="dataChart.chartOptions"
                       :series="dataChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BarChartComponent from '@/views/admin/category/charts/component/BarChartComponent.vue'

export default {
  name: 'StudentByItemApexChart',
  components: {
    BarChartComponent,
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    BCardTitle,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columnChart: {
        series: [
          {
            name: 'Khen thưởng',
            data: [],
          },
          {
            name: 'Kỷ luật',
            data: [],
          },
          {
            name: 'TCSP',
            data: [],
          },
          {
            name: 'CĐCS',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: [chartColors.column.series1, chartColors.column.series2, chartColors.column.series3, chartColors.column.series4],
          plotOptions: {
            bar: {
              columnWidth: '35%',
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
          },
          fill: {
            opacity: 1,
          },
        },
      },
      dataChart: {
        chartOptions: {},
        series: [{ data: [] }]},
      check: true
    }
  },
  computed: {
    ...mapGetters( {
      countStudentByItem: 'chart/countStudentByItem',
    }),
  },
  watch: {
    'filter.trainingSystemId': async function () {
      await this.getDataFromStore()
    },
    'filter.yearId': async function () {
      await this.getDataFromStore()
    },
    'filter.semesterId': async function () {
      await this.getDataFromStore()
    }
  },
  async created() {
    await this.getDataFromStore()
  },
  methods:{
    ...mapActions({
      getCountStudentByItem: 'chart/getCountStudentByItem',
    }),
    async getDataFromStore(){
      this.isLoading = true
      try {
        await this.getCountStudentByItem(this.filter)
        
        if (this.countStudentByItem.length > 0 ) {
          this.columnChart.chartOptions.xaxis.categories = []
          this.columnChart.series[0].data = []
          this.columnChart.series[1].data = []
          this.columnChart.series[2].data = []
          this.columnChart.series[3].data = []
          this.countStudentByItem.forEach((item, key) => {
            this.columnChart.chartOptions.xaxis.categories.push(item.departmentName)
            this.columnChart.series[0].data.push(item.countStudentAward)
            this.columnChart.series[1].data.push(item.countStudentDisciplines)
            this.columnChart.series[2].data.push(item.countStudentAllowances)
            this.columnChart.series[3].data.push(item.countStudentPolicies)
          })
        }
        if (this.columnChart.series[0].data.length === 0 ||
          this.columnChart.series[1].data.length === 0 ||
          this.columnChart.series[2].data.length === 0 ||
          this.columnChart.series[3].data.length === 0) {
          this.check = false
        }
        this.dataChart = {...this.columnChart}
        this.check = true
        this.isLoading = true
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  }
}
const chartColors = {
  column: {
    series2: '#299AFF',
    series1: '#28dac6',
    series3: '#836AF9',
    series4: '#FDAC34',
    bg: '#f8d3ff',
  },
}
</script>
